import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import * as React from "react"

import HeadingSeparator from "./HeadingSeparator"
import { Theme } from "./ThemeToggler"

import "./styles/post-header.scss"

interface PostHeaderProps {
  description: string
  icon?: string
  imageData?: IGatsbyImageData
  circledImage?: boolean
  theme: Theme
  title: string
}

const PostHeader: React.FC<PostHeaderProps> = (props) => (
  <div className={`post-header--${props.theme}`}>
    <div className={`post-header__content`}>
      {props.imageData &&
        <GatsbyImage
          alt={props.title}
          className={`post-header__image`}
          imgClassName={`${props.circledImage ? "circled" : ""}`}
          image={props.imageData!}
          loading="eager"
        />
      }

      <h1 className={`post-header__title fs45`}>
        {props.icon} {props.title}
      </h1>

      <h2 className={`post-header__description--${props.theme} fw-normal`}>
        {props.description}
      </h2>

      <HeadingSeparator theme={props.theme}/>
    </div>
  </div>
)

export default PostHeader