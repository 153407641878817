import * as React from "react"
import { graphql } from "gatsby"

import { IArticleNode } from "../types/Article"
import { ILocation } from "../types/Location"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ArticleTimeline, { makeArticleTimelineItem } from "../components/ArticleTimeline"
import PostHeader from "../components/PostHeader"

import "./styles/articles.scss"

interface ArticlesPageProps {
  data: {
    allArticles: {
      edges: [
        {
          node: {
            childMdx: IArticleNode
          }
        }
      ]
    }
  }

  location: ILocation
}

const ArticlesPage: React.FC<ArticlesPageProps> = ({ data, location }) => (
  <Layout currentPath={location.pathname}>
    {({ theme }) => (
      <>
        <SEO title="Articles" />

        <PostHeader
          description="I blog about challenges & interests I come across in various parts of my life. Hopefully you'll enjoy some of these."
          icon="📝"
          theme={theme}
          title="Articles"
        />

        <ArticleTimeline
          articles={data.allArticles.edges.map(({ node }) => makeArticleTimelineItem(node.childMdx))}
          currentPath={location.pathname}
          theme={theme}
          showFilter={true}
          showItemCategory={true}
        />
      </>
    )}
  </Layout>
)

export const pageQuery = graphql`
  query($category: String) {
    allArticles: allFile(
      filter: { childMdx: {frontmatter: {category: {eq: $category}}}, sourceInstanceName: { eq: "articles" } }
      sort: { fields: [childMdx___frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          childMdx {
            id
            body
            frontmatter {
              category
              date
              tags
              title
            }
            fields {
              slug
            }
          }
        }
      }
    }
  }
`

export default ArticlesPage
