import * as React from "react"
import { Link, graphql, navigate, useStaticQuery } from "gatsby"

import { ITopic } from "../types/Topic"
import { ContentCategory, IContentNode } from "../types/Content"

import ContentMetadata from "./ContentMetadata"
import { Theme } from "./ThemeToggler"

import "./styles/article-timeline.scss"

export interface IArticleTimelineItem {
  id: string
  date: Date
  title: string
  category: ContentCategory
  slug: string
  tags: string[]
}

export function makeArticleTimelineItem(article: IContentNode): IArticleTimelineItem {
  return {
    "id": article.id,
    "date": new Date(Date.parse(article.frontmatter.date)),
    "title": article.frontmatter.title,
    "category": article.frontmatter.category,
    "slug": article.fields.slug,
    "tags": article.frontmatter.tags
  }
}

function isFirstForYear(article: IArticleTimelineItem, articleYearMap: {[year: string] : IArticleTimelineItem[]}): boolean {
  return articleYearMap[`${article.date.getFullYear()}`]![0] === article
}

function makeArticleYearMap(articles: IArticleTimelineItem[]): {[year: string] : IArticleTimelineItem[]} {
  let map: { [year: string] : IArticleTimelineItem[] } = {}

  articles.forEach(article => {
    const yearKey = `${article.date.getFullYear()}`
    let yearArticles = map[yearKey]
    if (yearArticles === undefined) {
      yearArticles = []
    }
    yearArticles.push(article)
    map[yearKey] = yearArticles
  })

  return map
}

interface ArticleTimelineProps {
  articles: IArticleTimelineItem[]
  currentPath: string
  showFilter?: boolean
  showItemCategory?: boolean
  theme: Theme
}

interface ISiteData {
  siteMetadata: {
    topics: ITopic[]
  }
}

const ArticleTimeline: React.FC<ArticleTimelineProps> = ({ articles, currentPath, showFilter, showItemCategory, theme }) => {
  const { site }: { site: ISiteData } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          topics {
            icon
            title
            description
          }
        }
      }
    }
  `)

  const articleYearMap = makeArticleYearMap(articles)

  return (
    <div className={`article-timeline--${theme}`}>
      {showFilter &&
        <TimelineFilter
          currentPath={currentPath}
          filters={site.siteMetadata.topics.map((topic) => topic.title)}
          theme={theme}
        />
      }

      <div className="article-timeline__container">
        {
          articles.map((article) => (
            <TimelineItem
              article={article}
              isFirstForYear={isFirstForYear(article, articleYearMap)}
              key={article.id}
              showCategory={showItemCategory}
              theme={theme}
            />
          ))
        }
      </div>
    </div>
  )
}

export default ArticleTimeline

interface TimelineFilterProps {
  currentPath: string
  filters: string[]
  theme: Theme
}

const TimelineFilter: React.FC<TimelineFilterProps> = ({ currentPath, filters, theme }) => (
  <div className={`timeline-filter__container--${theme}`}>
    <strong className="timeline-filter__title fw-450">Topic Filters</strong>

    <ul className="timeline-filter__list">
      {
        ["All"].concat(filters).map((filter) => {
          const slug = filter === "All" ? "" : filter.toLowerCase()

          return (
            <li className={`timeline-filter__item`} key={filter.toLowerCase()}>
              <Link
                className={`
                  timeline-filter__link--${theme}
                  ${currentPath.trim().toLowerCase() == `/articles/${slug}` ? "active" : ""}
                `}
                to={`/articles/${slug}`}
              >
                {filter}
              </Link>
            </li>
          )
        })
      }
    </ul>
  </div>
)


interface TimelineItemProps {
  article: IArticleTimelineItem
  isFirstForYear: boolean
  theme: Theme
  showCategory?: boolean
}

const TimelineItem: React.FC<TimelineItemProps> = ({ article, isFirstForYear, theme, showCategory }) => (
  <article className="timeline-item">
    {isFirstForYear &&
      <h2 className={`timeline-item__year--${theme}`}>{article.date.getFullYear()}</h2>
    }

    <time className={`${isFirstForYear ? "" : "offset-time"} timeline-item__time--${theme}`} dateTime={`${article.date.toLocaleString()}`}>
      <span className="month">{article.date.toLocaleString('default', { month: 'short' })}</span>
      <span className="day">{article.date.toLocaleString('default', {day: '2-digit'})}</span>
      <span className="year">{article.date.getFullYear()}</span>
    </time>

    <div className="timeline-item__tagTitleWrapper">
      {showCategory &&
        <span className={`timeline-item__category--${theme} fs11`}>{article.category.toUpperCase()}</span>
      }

      <h1 className={`timeline-item__title--${theme} fw-350`} onClick={() => navigate(article.slug)}>{article.title}</h1>

      <ContentMetadata
        tags={article.tags}
        theme={theme}
      />
    </div>
  </article>
)